import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import OrdersTable from "@/components/Connections/OrdersTable";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import MyShopsMixin from "@/mixins/MyShops";
import HasTokens from "@/components/HasTokens";
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "Заказы"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("calendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("connections/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.connections.calendar
    };
  },
  computed: {
    fileName() {
      const split1 = this.reportSettings.date.split("-");
      const split2 = this.reportSettings.date2.split("-");
      const dates = [split1[1] + "-" + split1[0] + "-" + split1[2], split2[1] + "-" + split2[0] + "-" + split2[2]];
      return `${this.marketplace.title} Заказы ${dates[0]} - ${dates[1]}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        date: dates[0],
        date2: dates[1],
        tokens: this.selectedTokens.map(item => item.id)
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("connections/updateCalendar", this.calendar);
      localStorage.setItem("calendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    OrdersTable,
    HasTokens,
    DataLoading,
    DataTableActions
  }
};